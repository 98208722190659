.btn-srh-nop {
	width: 100%;
	margin-bottom: 5px;
}

.Chart-Container {
	height: 275px;
}

.container-table {
	max-width: 100%;
	overflow: hidden;
	z-index: 70;
}

#table-nop {
	width: 100%;
	border-collapse: collapse;
	min-width: 600px;
}

.detail-table {
	overflow-x: auto;
}

@media (min-width: 768px) {
	.btn-srh-nop {
		float: right;
		min-width: 84px;
		width: 84px;
		margin-top: 31px;
	}

	.Chart-Container {
		height: 350px;
	}
}